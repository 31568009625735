import { Header4 } from "@components/atoms/Typography";
import Button from "@components/molecules/Button";
import SocialBlock from "@components/molecules/SocialBlock";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useI18n from "@utils/hooks/useI18n";
import { string } from "prop-types";
import React from "react";

import { contactDataType } from "../index.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Logos = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const NewsletterWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    background-color: ${theme.brand("TdHPink")};
    border-radius: 1rem;
    padding: 1rem;
  `,
);

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Body = styled.span`
  line-height: 1.5;
`;

const ContactBlock = ({ data, lang }) => {
  const i18n = useI18n(lang);
  return (
    <Wrapper>
      {data.newsLetter && (
        <NewsletterWrapper>
          <Header4>{i18n.text("headings.subscribe_newsletter")}</Header4>
          <Body>{i18n.text("body.subscribe_newsletter")}</Body>
          <StyledButton black iconType="arrow" to={data.newsLetter.path}>
            {data.newsLetter.label}
          </StyledButton>
        </NewsletterWrapper>
      )}
      <SocialBlock socials={data.socials} color="white" />
      <Logos>{data.logos}</Logos>
    </Wrapper>
  );
};

ContactBlock.propTypes = {
  data: contactDataType,
  lang: string,
};

export default ContactBlock;
