import Icon from "@components/atoms/Icon";
import Link from "@components/atoms/Link";
import Logo from "@components/atoms/Logo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import { headerItemsType } from "../../__types__";
import CallToActionBlock from "./components/CallToActionBlock";
import Hamburger from "./components/Hamburger";
import DropdownMenu from "./components/Menu";

// TODO: refactor I18N implementation:
// https://greenberry.atlassian.net/browse/TDH-2
const translations = {
  nl: {
    menu: "Menu",
    close: "Sluiten",
    ariaLabelOpen: "Open het menu",
    ariaLabelClose: "Sluit het menu",
  },
  en: {
    menu: "Menu",
    close: "Close",
    ariaLabelOpen: "Open the menu",
    ariaLabelClose: "Close the menu",
  },
};

const HeaderWrapper = styled.div(
  ({ theme, showMenu }) => css`
    position: fixed;
    top: 0;
    width: 100%;
    background: ${theme.brand("TdHWhite")};
    z-index: ${theme.get("layers.menu")};
    @media (max-width: ${theme.get("breakpoints.lg")}) {
      position: ${showMenu ? "relative" : "fixed"};
    }
  `,
);

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  max-width: ${({ theme }) => theme.get("breakpoints.xl")};
  margin: 0 auto;
  padding: 1rem;
`;

const MenuButton = styled.button(
  ({ theme }) => css`
    display: inline-block;
    border: none;
    padding: 0.25rem;
    background-color: transparent;
    cursor: pointer;
    font-family: ${theme.get("type.fontFamily.secondary")};
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    color: ${theme.brand("TdHBlack")};

    &:focus {
      outline: none;
    }

    @media (min-width: ${theme.get("breakpoints.lg")}) {
      min-width: 72px;
    }
  `,
);

const StyledLogo = styled(Link)`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(props) => props.theme.get("breakpoints.lg")}) {
    margin-left: 2rem;
  }
  img {
    max-height: 3rem;
  }
`;

const MenuWrapper = styled.div(
  ({ theme, show }) => css`
    width: 100vw;
    transition: height 0.2s ease-out;
    @media (max-width: ${theme.get("breakpoints.lg")}) {
      height: ${show ? "100vh" : "72px"};
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      overflow-y: scroll;
    }
  `,
);

const Search = styled(Link)(
  ({ theme, active }) => css`
    display: none;
    @media (min-width: ${theme.get("breakpoints.lg")}) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
    color: ${theme.brand(active ? "TdHRed" : "TdHBlack")};
    font-weight: 600;
    text-decoration: none;
    position: relative;
    transition: color 0.2s ease-out;
    margin-left: 3rem;

    :hover {
      color: ${theme.brand("TdHRed")};
    }

    :after {
      display: ${active ? "block" : "none"};
      content: "\\00b7";
      font-size: 3rem;
      position: absolute;
      line-height: 0;
      bottom: -0.5rem;
      right: 50%;
      transform: translateX(50%);
    }
  `,
);

const MenuLabel = styled.div(
  ({ theme }) => css`
    display: none;
    @media (min-width: ${theme.get("breakpoints.lg")}) {
      display: block;
    }
    text-decoration: none;
  `,
);

const Header = ({
  locale,
  headerItems,
  menuItems,
  hidden,
  currentPath,
  localeOptions,
  onLocaleChange = null,
}) => {
  const [showMenu, toggleMenu] = useState(false);
  const headerRef = useRef(null);
  const searchParams = headerItems.main.find(({ icon }) => icon === "search");

  useEffect(() => {
    toggleMenu(!hidden && showMenu);
  }, [hidden, showMenu]);

  useEffect(() => {
    function checkOutsideClick(e) {
      if (headerRef.current && !headerRef.current.contains(e.target)) {
        toggleMenu(false);
      }
    }

    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [headerRef, showMenu]);

  return (
    <MenuWrapper ref={headerRef} show={showMenu}>
      <HeaderWrapper showMenu={showMenu}>
        <StyledHeader>
          <MenuButton
            onClick={() => toggleMenu(!showMenu)}
            showMenu={showMenu}
            aria-label={
              showMenu
                ? translations[locale].ariaLabelClose
                : translations[locale].ariaLabelOpen
            }
          >
            <Hamburger active={showMenu} />
            <MenuLabel>
              {translations[locale][showMenu ? "close" : "menu"]}
            </MenuLabel>
          </MenuButton>
          <StyledLogo to={`/${locale}`}>
            <Logo />
          </StyledLogo>

          <CallToActionBlock
            ctaItems={headerItems.cta}
            toggleMenu={toggleMenu}
            currentPath={currentPath}
          />
          <Search
            to={searchParams.path}
            active={currentPath === searchParams.path}
            showMenu={showMenu}
            onClick={() => toggleMenu(false)}
          >
            <Icon type="search" size={0.8} />
            <span>{searchParams.label}</span>
          </Search>
        </StyledHeader>
      </HeaderWrapper>
      <DropdownMenu
        show={showMenu}
        menuItems={menuItems}
        currentPath={currentPath}
        locale={locale}
        localeOptions={localeOptions}
        toggleMenu={toggleMenu}
        onLocaleChange={onLocaleChange}
      />
    </MenuWrapper>
  );
};

Header.propTypes = headerItemsType;

export default Header;
