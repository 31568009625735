import icons from "@components/atoms/Icon/icons";

const camelToSnake = (string) =>
  string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1_$2").toLowerCase();

export const SOCIALS = {
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  LINKED_IN: "linkedIn",
  YOUTUBE: "youtube",
};

export const BIG_ICONS = {
  MONEY: "money",
  TIME: "time",
  STUFF: "stuff",
};

export const ICONS = Object.keys(icons).reduce(
  (acc, key) => {
    acc[camelToSnake(key).toUpperCase()] = key;
    return acc;
  },
  {
    ...SOCIALS,
    ...BIG_ICONS,
  },
);
