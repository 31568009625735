import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { bool } from "prop-types";

const fontSizes = {
  small: "0.875rem",
  normal: "1rem",
  intro: "1.25rem",
};

const lineHeights = {
  small: "1.05rem",
  normal: "1.5rem",
  intro: "1.875rem",
};

const Paragraph = styled.p(
  ({ theme, bold, small, intro, white }) => css`
    font-family: ${theme.get("type.fontFamily.secondary")};
    font-weight: ${bold
      ? theme.get("type.fontWeight.bold")
      : theme.get("type.fontWeight.normal")};
    margin: 0;
    font-size: ${small || intro
      ? fontSizes[small ? "small" : "intro"]
      : fontSizes.normal};
    line-height: ${small || intro
      ? lineHeights[small ? "small" : "intro"]
      : lineHeights.normal};
    color: ${theme.brand(white ? "TdHWhite" : "TdHBlack")};
  `,
);

Paragraph.propTypes = {
  small: bool,
  intro: bool,
  white: bool,
  bold: bool,
};

export default Paragraph;
