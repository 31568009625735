import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { string } from "prop-types";
import React from "react";

import { menuItemsType } from "../../__types__";
import ContactBlock from "./components/ContactBlock";
import MainNav from "./components/MainNav";
import SubNav from "./components/SubNav";
import { contactDataType } from "./index.types";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.brand("TdHBlack")};
  overflow: hidden;
`;

const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.get("breakpoints.lg")};
    display: flex;
    flex-direction: column;
    margin: 2.5rem 1rem 2.5rem;
    gap: 1.5rem;
    @media (min-width: ${theme.get("breakpoints.md")}) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin: 3.5rem 1.5rem;
    }
  `,
);

const MainLinks = styled.div(
  ({ theme }) => css`
    @media (min-width: ${theme.get("breakpoints.md")}) {
      grid-column: 2 / 4;
    }
  `,
);

const SubLinks = styled.div(
  ({ theme }) => css`
    @media (min-width: ${theme.get("breakpoints.md")}) {
      grid-column: 4 / 8;
    }
  `,
);

const Contact = styled.div(
  ({ theme }) => css`
    @media (min-width: ${theme.get("breakpoints.md")}) {
      grid-column: 9 / 13;
    }
  `,
);

const Footer = ({ footerItems, contactData, lang }) => {
  const { main, sub, cta } = footerItems;
  return (
    <Wrapper>
      <Container>
        <MainLinks>
          <MainNav navItems={main} callToActions={cta} />
        </MainLinks>
        <SubLinks>
          <SubNav navItems={sub} />
        </SubLinks>
        <Contact>
          <ContactBlock data={contactData} lang={lang} />
        </Contact>
      </Container>
    </Wrapper>
  );
};

Footer.propTypes = {
  footerItems: menuItemsType,
  contactData: contactDataType,
  lang: string,
};

export default Footer;
