import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { bool } from "prop-types";
import React from "react";

import {
  toBlack,
  toBrown,
  toPrimary,
  toPrimaryBlack,
} from "./animateBackground";
import BaseButton, { StyledIcon } from "./components/BaseButton";

const solidStyleMap = {
  black: {
    background: "TdHBlack",
    color: "TdHWhite",
    hover: toPrimary,
  },
  white: {
    background: "TdHWhite",
    color: "TdHRed",
    hover: toBlack,
  },
  brown: {
    background: "TdHDarkBrown",
    color: "TdHWhite",
    hover: toBrown,
  },
  default: {
    background: "TdHRed",
    color: "TdHWhite",
    hover: toBlack,
  },
};

const outlineStyleMap = {
  black: {
    color: "TdHBlack",
    border: "TdHBlack",
    hover: toBlack,
  },
  white: {
    color: "TdHWhite",
    border: "TdHWhite",
    hover: toBlack,
  },
  brown: {
    color: "TdHDarkBrown",
    border: "TdHDarkBrown",
    hover: toBrown,
  },
  default: {
    color: "TdHRed",
    border: "TdHRed",
    hover: toPrimary,
  },
};

const iconStyleMap = {
  brown: {
    background: "TdHDarkBrown",
    color: "TdHWhite",
    hover: toBlack,
  },
  orange: {
    background: "TdHOrange",
    color: "TdHBlack",
    hover: toPrimaryBlack,
  },
  default: {
    background: "TdHBlack",
    color: "TdHWhite",
    hover: toPrimary,
  },
};

const getStyle = (props, styleMap) => {
  const styleType = Object.keys(styleMap).find((type) => props[type]);
  return styleMap[styleType] || styleMap.default;
};

const SolidButton = styled(BaseButton)(({ theme, ...props }) => {
  const styles = getStyle(props, solidStyleMap);
  return css`
    background: ${theme.brand(styles.background)};
    color: ${theme.brand(styles.color)};

    flex-direction: row;
    gap: 0.5rem;

    ${styles.hover(theme)};
  `;
});

const OutlineButton = styled(BaseButton)(({ theme, ...props }) => {
  const styles = getStyle(props, outlineStyleMap);
  return css`
    background: ${theme.brand("TdHWhite")};
    color: ${theme.brand(styles.color)};
    border: 1px solid transparent;
    border-color: ${theme.brand(styles.border)};
    flex-direction: row;
    ${StyledIcon} {
      margin-left: 0.5rem;
    }
    ${styles.hover(theme)};
  `;
});

const RoundButton = styled(BaseButton)(
  ({ theme }) => css`
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 50%;
    color: ${theme.brand("TdHWhite")};
    background: ${theme.brand("TdHRed")};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ${StyledIcon} {
      position: absolute;
      transform: translateY(100%);
      left: 0;
      right: 0;
      bottom: 1rem;
      margin: 0.8rem auto;
    }
    ${toBlack(theme)};
  `,
);

const IconButton = styled(BaseButton)(({ theme, ...props }) => {
  const styles = getStyle(props, iconStyleMap);
  return css`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    overflow: hidden;
    color: ${theme.brand(styles.color)};
    background: ${theme.brand(styles.background)};
    display: flex;
    flex-direction: column;
    .stroke {
      stroke-width: 2;
    }
    ${styles.hover(theme)};
  `;
});

const Button = (props) => {
  const { outline, round, icon } = props;
  return outline || round || icon ? (
    <>
      {outline && <OutlineButton {...props} />}
      {round && <RoundButton {...props} />}
      {icon && <IconButton {...props} />}
    </>
  ) : (
    <SolidButton {...props} />
  );
};

Button.propTypes = {
  outline: bool,
  round: bool,
  icon: bool,
  black: bool,
  white: bool,
  brown: bool,
  orange: bool,
  ...BaseButton.propTypes,
};

export default Button;
