import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { array, bool, node, oneOfType, string } from "prop-types";
import React from "react";

const Wrapper = styled.div(
  ({ theme, white }) => css`
    white-space: normal;
    font-family: ${theme.get("type.fontFamily.secondary")};
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${white ? theme.brand("TdHWhite") : theme.brand("TdHBlack")};
    ul,
    ol {
      margin-left: 0;
      padding-left: 1rem;
      white-space: normal;
      li {
      }
    }
    a {
      color: ${white ? theme.brand("TdHWhite") : theme.brand("TdHRed")}};
    }
  `,
);

const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);
const Wysiwyg = ({ white = false, children, ...props }) =>
  isHTML(children) ? (
    <Wrapper
      white={white}
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    />
  ) : (
    <Wrapper white={white} {...props}>
      {children}
    </Wrapper>
  );

Wysiwyg.propTypes = {
  white: bool,
  children: oneOfType([string, node, array]),
};

export default Wysiwyg;
