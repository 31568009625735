import Link from "@components/atoms/Link";
import { LOCALE } from "@constants";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { arrayOf, func, oneOf, shape, string } from "prop-types";
import React from "react";

const emptyArray = [];

const Locale = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledHeader = styled.span(
  ({ theme, active }) => css`
    color: ${theme.brand("TdHBlack")};
    display: inline-block;
    text-transform: uppercase;
    font-weight: ${active
      ? `${theme.get("type.fontWeight.bold")}`
      : `${theme.get("type.fontWeight.medium")}`};
    position: relative;

    transition: color 0.2s ease-out;

    &:hover {
      color: ${theme.brand("TdHRed")};
    }

    :after {
      display: ${active ? "block" : "none"};
      content: "\\00B7";
      position: absolute;
      bottom: -0.25rem;
      left: 50%;
      transform: translateX(-50%);
      line-height: 0;
    }
  `,
);

const Slash = styled.span(
  ({ theme }) => css`
    color: ${theme.brand("TdHBlack")};
    display: inline-block;
    margin: 0 0.5rem;
    font-weight: ${theme.get("type.fontWeight.medium")};
  `,
);

const SelectLocale = ({
  className,
  activeLocale,
  toggleMenu,
  options = emptyArray,
  onLocaleChange,
}) => (
  <div className={className}>
    {options.map(({ to, locale }, index) => (
      <React.Fragment key={locale}>
        <Locale
          to={to}
          onClick={() => {
            onLocaleChange?.(locale);
            toggleMenu?.(false);
          }}
        >
          <StyledHeader active={activeLocale === locale}>{locale}</StyledHeader>
        </Locale>
        {index + 1 !== options.length && <Slash>/</Slash>}
      </React.Fragment>
    ))}
  </div>
);

SelectLocale.propTypes = {
  color: string,
  className: string,
  activeLocale: oneOf(Object.values(LOCALE)),
  options: arrayOf(
    shape({
      to: string.isRequired,
      locale: oneOf(Object.values(LOCALE)).isRequired,
    }).isRequired,
  ).isRequired,
  toggleMenu: func.isRequired,
  onLocaleChange: func.isRequired,
};

export default SelectLocale;
