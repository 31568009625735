import Layout from "@components/atoms/Layout";
import Link from "@components/atoms/Link";
import Footer from "@components/organisms/Footer";
import Header from "@components/organisms/Header";
import { NAV_LABELS, PATHS } from "@constants";
import { ICONS, SOCIALS } from "@constants/icons";
import styled from "@emotion/styled";
import I18nContext from "@utils/context/I18nContext";
import LocaleContext from "@utils/context/LocaleContext";
import { formatNavs } from "@utils/formatNavs";
import getNavigationItem from "@utils/getNavigationItem";
import getUserLang from "@utils/getUserLang";
import I18n from "@utils/I18n";
import removeTrailingSlashes from "@utils/removeTrailingSlashes";
import { renderNavs } from "@utils/renderNavs";
import { initiateCheckout } from "@utils/sendFacebookEvent";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";

import chsLogo from "../../images/footer/chs.svg";
import hqaiLogo from "../../images/footer/hqai.svg";
import partosLogo from "../../images/footer/partos.svg";
import anbi from "../../images/Logo_Anbi.png";
import cbf from "../../images/Logo_Erkend_Goed_Doel_zw.png";
import npcl from "../../images/Logo_NPCL.png";
import iso from "../../images/logo-iso.png";

const FooterLogo = styled.img`
  position: relative;
  width: 4rem;
  height: 4rem;
  transition: opacity 0.3s ease-out;
  @media (min-width: ${({ theme }) => theme.get("breakpoints.md")}) {
    width: 3rem;
    height: 3rem;
  }
  &:hover {
    opacity: 0.6;
  }
`;

const navCategories = [
  {
    key: "join_fight",
    items: ["become_donor", "partners", "petition", "donate_goods"],
  },
  {
    key: "about_what_we_do",
    items: ["about_mission", "projects", "countries", "results"],
  },
  {
    key: "our_organisation",
    items: ["history", "ambassadors", "jobs", "integrity"],
  },
  {
    key: "about_stores",
    items: ["become_volunteer"],
  },
  {
    key: "news",
    items: ["currently", "publications", "factsheets"],
  },
  {
    key: "contact",
    items: ["update_data", "faq", "press"],
  },
];

const navigation = (locale) =>
  navCategories.map((category) => ({
    ...getNavigationItem(category.key, locale),
    items: category.items
      .map((item) => getNavigationItem(item, locale))
      .filter(Boolean),
  }));

const mainNavigation = (locale) => {
  const items =
    locale === "en"
      ? ["about_mission", "about_what_we_do", "countries", "search"]
      : ["about_mission", "about_what_we_do", "about_stores", "search"];
  return renderNavs(items, locale);
};

const subNavigation = (locale) => {
  const items =
    locale === "en"
      ? [
          "currently",
          "publications",
          "our_organisation",
          "contact",
          "partners",
          "jobs",
        ]
      : [
          "currently",
          "publications",
          "our_organisation",
          "countries",
          "contact",
          "partners",
          "jobs",
        ];
  return renderNavs(items, locale);
};

const callToActions = (locale, navlinks, location) => {
  return [
    {
      label: navlinks.donate.label,
      path: navlinks.donate.value,
      icon: ICONS.HEART,
      trackEvent: () => initiateCheckout(location),
    },
    {
      label: navlinks.join_fight.label,
      path: navlinks.join_fight.value,
      icon: ICONS.HAND,
    },
  ];
};

const headerItems = (locale, navlinks) => ({
  main: mainNavigation(locale),
  cta: callToActions(locale, navlinks, "header"),
});

const footerItems = (locale, navlinks) => ({
  main: mainNavigation(locale),
  sub: [
    ...subNavigation(locale),
    ...renderNavs(["cookies", "privacy"], locale),
  ],
  cta: callToActions(locale, navlinks, "footer"),
});

const logoLinks = (locale) =>
  [
    {
      title: "anbi",
      alt: "Logo ANBI",
      url: "https://anbi.nl/",
      image: anbi,
    },
    {
      title: "npcl",
      alt: "Logo Nationale Postcode Loterij",
      url: "https://www.postcodeloterij.nl/goede-doelen/overzicht/terre-des-hommes",
      image: npcl,
    },
    locale === "nl"
      ? {
          title: "ISO 9001",
          alt: "Logo ISO 9001",
          url: "https://www.lrqa.com/nl-nl/iso-9001/",
          image: iso,
        }
      : {},
    {
      title: "cbf",
      alt: "Logo CBF",
      url: "https://www.cbf.nl/organisatie/terre-des-hommes-nederland",
      image: cbf,
    },
    {
      title: "iso-partos",
      alt: "Logo ISO 9001",
      url: "https://www.partos.nl/publicatie/partos-9001/",
      image: partosLogo,
    },
    {
      title: "chs",
      alt: "Logo Core Humanitarian Standard",
      url: "https://www.corehumanitarianstandard.org/",
      image: chsLogo,
    },
    locale === "en" && {
      title: "hqai",
      alt: "Logo Humanitarian Quality Assurance Initiative",
      url: "https://www.hqai.org/en/network/audited-partners/independently-verified-partners/Terre-des-hommes-Netherlands/",
      image: hqaiLogo,
    },
  ].filter(Boolean);

const contactData = (locale) => ({
  heading: "",
  newsLetter:
    locale === "nl"
      ? {
          path: PATHS[locale].newsLetter,
          label: NAV_LABELS[locale].newsLetter,
        }
      : null,
  logos: logoLinks(locale).map((data, i) => {
    return (
      <Link to={data.url} key={`${data.title}-${i}`}>
        <FooterLogo src={data.image} alt={data.alt} />
      </Link>
    );
  }),
  socials: [
    {
      type: SOCIALS.FACEBOOK,
      target: "https://www.facebook.com/terredeshommes.nl/",
    },
    {
      type: SOCIALS.INSTAGRAM,
      target: "https://www.instagram.com/terredeshommesnl",
    },
    {
      type: SOCIALS.LINKED_IN,
      target: "https://www.linkedin.com/company/terre-des-hommes-netherlands",
    },
    {
      type: SOCIALS.YOUTUBE,
      target: "https://www.youtube.com/channel/UCYtNkCylPGEvRDJeEFIoIVw",
    },
  ],
});

const setPreferredLanguage = (locale) => {
  global.window &&
    window.localStorage &&
    global.window.localStorage.setItem("__gb_lng_pref", locale);
};

const formatI18n = (locale, data) =>
  data[locale].map((item) => ({
    value: item.node.value,
    key: item.node.key,
  }));

const LayoutComponent = ({
  children,
  pageContext,
  location: { pathname, search },
  navigate,
}) => {
  const { lang = "nl" } = pageContext;
  const userLang = getUserLang(lang);

  useEffect(() => {
    // always redirect UpperCase paths to lowercase
    if (pathname !== pathname.toLowerCase()) {
      window.location.replace(pathname.toLowerCase() + search);
    }
    // can safely ignore the following warning because we only want to redirect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    site: {
      siteMetadata: { siteUrl },
    },
    en,
    nl,
    navNl,
    navEn,
  } = useStaticQuery(graphql`
    query getLayoutData {
      site {
        siteMetadata {
          siteUrl
        }
      }
      nl: allDatoCmsFunctionalCopy(locale: "nl") {
        nl: edges {
          node {
            key
            value
          }
        }
      }
      en: allDatoCmsFunctionalCopy(locale: "en") {
        en: edges {
          node {
            key
            value
          }
        }
      }
      navNl: allDatoCmsNavigation(limit: 100, locale: "nl") {
        navNl: edges {
          node {
            key
            label
            value
          }
        }
      }
      navEn: allDatoCmsNavigation(limit: 100, locale: "en") {
        navEn: edges {
          node {
            key
            label
            value
          }
        }
      }
    }
  `);

  const i18n = useMemo(
    () =>
      new I18n(
        {
          en: formatI18n("en", en),
          nl: formatI18n("nl", nl),
        },
        lang,
      ),
    [lang, en, nl],
  );

  useEffect(() => {
    if (
      lang !== userLang &&
      (pathname === "nl" || pathname === "en") &&
      typeof navigate === "function"
    ) {
      const to = pageContext[`${userLang}Path`] || `/${userLang}/`;
      navigate(to, { replace: true });
    }
  }, [navigate, userLang, lang, pageContext, pathname]);

  if (pageContext.layout === "no-layout") {
    return children;
  }

  const navLinks = formatNavs({ en: navEn.navEn, nl: navNl.navNl }, lang);
  let currentFullUrl = `${siteUrl}/${lang}`;
  if (lang === "en" && pageContext?.enPath) {
    currentFullUrl = removeTrailingSlashes(`${siteUrl}${pageContext.enPath}`);
  } else if (lang === "nl" && pageContext?.nlPath) {
    currentFullUrl = removeTrailingSlashes(`${siteUrl}${pageContext.nlPath}`);
  }

  return (
    <LocaleContext.Provider value={lang}>
      <I18nContext.Provider value={i18n}>
        <Helmet>
          <link
            rel="alternate"
            hrefLang="nl"
            href={`${siteUrl}${pageContext.nlPath}`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`${siteUrl}${pageContext.enPath}`}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`${siteUrl}${pageContext.enPath}`}
          />
          <link rel="canonical" href={currentFullUrl} />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "NGO",
              name: "Terre des Hommes",
              alternateName: "TDH",
              url: "https://www.terredeshommes.nl/",
              logo: "https://www.terredeshommes.nl/images/tdh_logo_black.svg",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+3170 310 5000",
                contactType: "customer service",
                contactOption: "TollFree",
                areaServed: "NL",
                availableLanguage: "Dutch",
              },
              sameAs: [
                "https://www.facebook.com/terredeshommes.nl/",
                "https://x.com/terredeshommes",
                "https://www.instagram.com/terredeshommesnl",
                "https://www.youtube.com/channel/UCYtNkCylPGEvRDJeEFIoIVw",
                "https://www.linkedin.com/company/terre-des-hommes-netherlands",
              ],
            })}
          </script>
        </Helmet>
        <Layout>
          <Header
            headerItems={headerItems(lang, navLinks)}
            menuItems={navigation(lang)}
            currentPath={pathname}
            locale={lang}
            onLocaleChange={setPreferredLanguage}
            localeOptions={[
              {
                locale: "nl",
                to: pageContext.nlPath + search,
              },
              {
                locale: "en",
                to: pageContext.enPath + search,
              },
            ]}
          />
          {children}
          <footer style={{ marginTop: "3rem" }}>
            <Footer
              footerItems={footerItems(lang, navLinks)}
              contactData={contactData(lang)}
              lang={lang}
            />
          </footer>
        </Layout>
      </I18nContext.Provider>
    </LocaleContext.Provider>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  navigate: PropTypes.func,
  pageContext: PropTypes.shape({
    layout: PropTypes.string,
    lang: PropTypes.oneOf(["nl", "en"]).isRequired,
    nlPath: PropTypes.string.isRequired,
    enPath: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    origin: PropTypes.string,
  }),
};

export default LayoutComponent;
